.comp-filter-special-button {
  width: fit-content;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: -4px;

  &:hover, &:has(*:focus-visible) {
    >.effect-container {
      background-color: #ffffff60;
      padding: 2px;
    }

    >.content {
      >.label {
        box-shadow:
          0 1px 5px 0 #00000040,
          inset 0 5px 5px -5px #ffffff40,
          inset 0 1px 0 0 #ffffff60;
          cursor: pointer;
      }
    }
  }

  >.effect-container {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 1px;
    transition: all 100ms ease-out;
    background-color: #ffffff20;
    z-index: -1;
    mask-clip: content-box, border-box;
    mask-image: linear-gradient(black, transparent), linear-gradient(black, black);
    mask-composite: exclude, exclude;
    border-radius: 10px;

    >.effect {
      height: 200%;
      aspect-ratio: 1 / 1;
      background: conic-gradient(transparent 0.75turn, var(--comp-filter-special-button-color));
      position: absolute;
      top: -50%;
      left: 25%;
      animation: circle 1500ms linear infinite;

      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background: conic-gradient(transparent 0.95turn, var(--comp-filter-special-button-color));
        mix-blend-mode: color-dodge;
      }
    }
  }

  >.content {
    width: 100%;
    height: 100%;
    padding: 4px;

    >.label {
      box-shadow:
        0 1px 5px 0 #00000040,
        inset 0 5px 5px -5px #ffffff40,
        inset 0 1px 0 0 #ffffff20;
      height: 30px;
      width: 100%;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      background: var(--comp-filter-special-button-color);
      display: flex;
      align-items: center;
      border-radius: 6px;
    }
  }
}

@keyframes circle {
  0% {
    transform: rotateZ(45deg);
    left: 25%;
  }

  18% {
    transform: rotateZ(135deg);
    left: 25%;
  }

  50% {
    transform: rotateZ(225deg);
    left: -25%;
  }

  68% {
    transform: rotateZ(315deg);
    left: -25%;
  }

  100% {
    transform: rotateZ(405deg);
    left: 25%;
  }
}