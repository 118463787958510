.comp-filter-range-picker {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  
  >.picker {
    height: 40px;
    position: relative;
    margin-right: 1px;
    
    >.tag {
      cursor: pointer;
      user-select: none;
      position: absolute;
      top: 0;
      left: var(--percent);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      width: 1px;

      &.drag {
        opacity: 0.5;
      }

      &.ghost {
        pointer-events: none;
      }

      >.number {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height: 31px;
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        background-color: var(--filter-ui-99);
        box-shadow: inset 0 -1px 0 0 var(--filter-ui-90);
        color: var(--filter-ui-pink);
        font-size: 12px;
      }

      >.arrow {
        margin-top: -1px;
        display: flex;
        transform: rotateZ(180deg);
        height: 5px;
        width: 10px;
      }
    }
  }

  >.slider {
    height: 10px;
    box-shadow: inset 0 0 0 1px var(--filter-ui-90);
    width: 100%;
    padding-right: 1px;

    >.container {
      height: 10px;
      width: 100%;
      position: relative;
      display: flex;

      >.line {
        position: absolute;
        top: 0;
        left: var(--percent);
        height: 10px;
        width: 1px;
        background-color: var(--filter-ui-pink);
      }

      >.area {
        position: absolute;
        top: 0;
        left: var(--minPercent);
        height: 10px;
        width: calc(var(--maxPercent) - var(--minPercent) + 1px);
        background-color: var(--filter-ui-99);
      }
    }
  }
}