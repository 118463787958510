.filter-comp-select-thumb {
  display: flex;
  flex-direction: column;
  
  >.option {
    display: flex;
    padding: 5px;
    gap: 5px;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;

    >.title {
      font-size: 12px;
      font-weight: 400;
    }

    >.thumbnail {
      height: 30px;
      width: 30px;
      border-radius: 5px;
      overflow: hidden;
      background-color: var(--filter-ui-99);
      border: 1px solid var(--filter-ui-93);
      
      >img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    
    &.selected {
      background-color: var(--filter-ui-99);
    }
    
    &:hover, &:focus-visible {
      box-shadow: inset 0 0 0 1px var(--filter-ui-90);
    }
  }
}